import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core';
import {Add, Close} from '@material-ui/icons';
import {toast} from 'react-toastify';

import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootState} from '../../store/reducers';
import {useBatchStoreActions, useMachineStoreActions} from '../../store/actions';
import {buildsCreatePOST} from '../../api/ajax/builds';
import {IBuild} from '@common/api/models/builds/IBuild';
import CustomButton from '../../components/atoms/CustomButton';
import IndexPage from '../shared/IndexPage/IndexPage';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {Role} from '@common/api/models/users/IUser';
import {useBuildAttachmentStoreActions} from '../../store/actions/index';
import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';
import {uniq} from 'lodash';

export function CreateNewBuildButton() {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    errors,
    handleSubmit: onSubmit,
  } = useForm<IBuild>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const handleAddBuild = async () => {
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleConfirm = async (data: Partial<IBuild>) => {
    const res = await buildsCreatePOST(data);
    if (res.success) {
      const draft = res.data;
      if (draft.useAdvancedWorkflow) {
        localStorage.setItem(`${draft.uuid}-advanced-draft`, 'true');
        history.push(`/builds/uuid/${draft.uuid}/draft/advanced/`);
      } else {
        history.push(`/builds/uuid/${draft.uuid}/draft/simple/`);
      }
    } else {
      toast(res.message, {type: 'error'});
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleAddBuild}>
        <Add />
        New Build
      </Button>
      <Dialog open={modalOpen} onClose={handleCancel} fullWidth={true} maxWidth="xs">
        <DialogTitle id="confirmation-dialog-title" disableTypography style={{padding: '24px'}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4">New Build</Typography>
            <IconButton aria-label="close" onClick={handleCancel} style={{padding: '0px'}}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: '24px 0px 24px 0px',
            margin: '0px 24px',
            overflow: 'hidden',
          }}
        >
          <form id="buildNameForm">
            <TextField
              fullWidth
              name="name"
              helperText={errors.name?.message}
              error={!!errors.name}
              label="Build name"
              variant="outlined"
              size="small"
              placeholder="Build name..."
              inputRef={register({
                required: 'Name is required',
                minLength: {
                  value: 3,
                  message: 'Name must be at least 3 characters',
                },
                maxLength: {
                  value: 1024,
                  message: "Name can't be more than 1024 characters",
                },
              })}
              inputProps={{'aria-required': true}}
              autoComplete="off"
              autoFocus
            />
          </form>
        </DialogContent>
        <DialogActions style={{padding: '16px 24px'}}>
          <Grid container justifyContent="space-between">
            <Button onClick={handleCancel} variant="outlined" color="primary">
              Cancel
            </Button>
            <CustomButton
              onClick={onSubmit(handleConfirm)}
              type="submit"
              form="buildNameForm"
              variant="contained"
              color="primary"
              text="Create"
              startIcon={<Add />}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const useData = () => {
  const buildsList = useSelector((state: RootState) => {
    return state.buildStore.list;
  });
  const machineById = useSelector((s: RootState) => s.machineStore.byId);
  const batchById = useSelector((s: RootState) => s.batchStore.byId);
  const buildAttachmentsById = useSelector((s: RootState) => s.buildAttachmentStore.byId);
  const currentUser = useSelector((s: RootState) => s.auth.user!);
  const userPermissionsById = useSelector((s: RootState) => s.authorizationStore.byId);
  const userPermissions = Object.values(userPermissionsById);
  const machineActions = useMachineStoreActions();
  const batchActions = useBatchStoreActions();
  const buildAttachmentStoreActions = useBuildAttachmentStoreActions();

  useEffect(() => {
    if (buildsList.length) {
      const machineUuids = buildsList.map((b) => b.machineUuid!).filter((s) => s);
      machineActions.ensureConsistent({uuid: uniq(machineUuids)});

      const batchUuids = buildsList.map((b) => b.batchUuid!).filter((s) => s);
      batchActions.ensureConsistent({uuid: uniq(batchUuids)});

      const buildUuids = buildsList.map((b) => b.uuid);
      buildAttachmentStoreActions.ensureConsistent({
        buildUuid: uniq(buildUuids),
        type: BuildAttachmentType.BuildPhotoMain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildsList]);

  return buildsList.map((build): any => {
    const machine = machineById[build.machineUuid!];
    const batch = batchById[build.batchUuid!];
    const hasPermissionOnMachine =
      currentUser.role >= Role.MANAGER ||
      userPermissions.find(
        (permission) =>
          permission.resourceType === ResourcePermissionType.MACHINE && permission.resourceUuid === build.machineUuid
      );

    return {
      name: build.name,
      state: build.state,
      machine: machine ? machine.name : !!build.machineUuid && !hasPermissionOnMachine ? '*******' : '',
      created: build.createdAt,
      start: build.start,
      end: build.end,
      batch: batch?.name || '',
      onClickUrl: `/builds/uuid/${build.uuid}`,
      build: build,
      avatarUrl: Object.values(buildAttachmentsById).find(
        (attachment) => attachment.buildUuid === build.uuid && attachment.type === BuildAttachmentType.BuildPhotoMain
      )?.signedUrl,
      layer:
        !build.numLayers && !build.lastPrintedLayerId
          ? ''
          : `${build.lastPrintedLayerId || '-'} / ${build.numLayers || '-'}`,
    };
  });
};

export function BuildsPage() {
  return <IndexPage resourceType="build" CreateResourceComponent={CreateNewBuildButton} useData={useData} />;
}
